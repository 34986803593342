import Modal from "components/Modal"
import { solcVersions } from "constants/solc"
import { useProject } from "contexts/Project"
import { AwaitableModal } from "hooks/useAwaitableModal"
import { useState } from "react"
import { Tooltip } from "react-tooltip"

const RequestCertificationsModal:
    AwaitableModal<undefined, {fileAddresses: Record<string, string>, chain: string}> = ({ resolve, onCancel, initialData, open }) => {
      const { onCertify } = useProject()
      const [optimized, setOptimized] = useState<boolean>(false)
      const [solcVersion, setSolcVersion] = useState<string>("")
      const [runs, setRuns] = useState<number>(200)

      const requestCertifications = async() => {
        if (!solcVersion) return
        resolve()
        onCertify(initialData.chain, initialData.fileAddresses, {
          solcVersion,
          optimized: optimized || false,
          runs: runs || 200,
        })
        resolve()
      }

      return (
        <Modal open={open} close={resolve}>
          <div className="bg-bg-primary rounded-lg shadow-lg h-max max-h-full flex flex-col">
            <h1 className="text-2xl font-bold p-4 text-white">Request Certifications</h1>
            <div className="overflow-y-auto px-4 max-h-max pb-4">
              <div className="flex flex-col">
                <span className="text-sm text-white font-medium mb-2">Files</span>
                {
                  Object.entries(initialData.fileAddresses).map(([name, address]) => (
                    <div
                      key={name}
                      className={`flex items-center justify-between p-3 
                    rounded-lg border border-border-primary
                     bg-black`}
                    >
                      <span className="text-xs text-white">{name}</span>
                      <span className="text-xs text-white">{address}</span>
                    </div>
                  ))
                }
                <span className="text-sm text-white font-medium mt-4">Compiler Settings</span>
                <div className="flex flex-col gap-3 mt-3">
                  <select
                    className="rounded-lg border border-border-primary p-3 bg-black text-white outline-none"
                    value={solcVersion}
                    onChange={e => setSolcVersion(e.target.value)}
                  >
                    <option value="">Select solc version</option>
                    {solcVersions.map(v => (
                      <option key={v} value={v}>{v}</option>
                    ))}
                  </select>
                  <label className="flex items-center justify-between p-3 rounded-lg border border-border-primary bg-black">
                    <span className="text-sm text-white">Optimized</span>
                    <input
                      type="checkbox"
                      checked={optimized}
                      className="rounded-full border border-border-primary p-4 bg-black text-white"
                      onChange={e => setOptimized(e.target.checked)}
                    />
                  </label>
                  <input
                    type="number"
                    value={runs}
                    className="rounded-lg border border-border-primary p-3 bg-black text-white outline-none"
                    placeholder="Runs"
                    onChange={e => setRuns(Number(e.target.value))}
                  />
                </div>
                <button
                  onClick={requestCertifications}
                  data-tooltip-id="request-certifications"
                  className="bg-white  transition-all hover:bg-slate-100 flex items-center gap-3 w-max self-center
                 py-4 px-8 rounded-full text-black relative mt-4"
                >
                  <span>Request Certifications</span>
                </button>
                {!solcVersion && (
                  <Tooltip id="request-certifications">
                    <span>Select Solc Version to Continue</span>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )
    }

export default RequestCertificationsModal
