import { BackIcon } from "assets/icons"
import Loader from "components/Loader"
import { supportedChainsConfig } from "constants/chains"
import { useProject } from "contexts/Project"
import useAwaitableModal from "hooks/useAwaitableModal"
import { FC, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import RequestCertificationsModal from "./modals/RequestCertifications"
import { Tooltip } from "react-tooltip"

const ProjectNetworkCertificationsPage: FC = () => {
  const { project, certifications, latestVersionData } = useProject()
  const navigate = useNavigate()
  const { chain } = useParams() as { chain: string }
  const scannerConfig = supportedChainsConfig.find(c => c.id === chain)
  const [fileAddresses, setFileAddresses] = useState<Record<string, string>>({})
  const [requestCertifications, RequestCertificationsComponent] = useAwaitableModal(RequestCertificationsModal, { fileAddresses } as any)

  useEffect(() => {
    setFileAddresses({})
  }, [certifications, chain, project?.id])

  if (!certifications || !latestVersionData) return (
    <div className="w-full h-full overflow-hidden bg-black">
      <Loader />
    </div>
  )

  if (!scannerConfig) return (
    <div className="w-full h-full overflow-hidden bg-black">
      <span className="font-medium text-white">Invalid chain</span>
    </div>
  )

  const projectCertifications = Object.values(certifications)
  const latestVersionFiles = latestVersionData.files
  const fileCertificationsByChain = projectCertifications.reduce((acc, cert) => {
    return {
      ...acc,
      [cert.chain]: [...(acc[cert.chain] || []), cert.fileName],
    }
  }, {} as Record<string, string[]>)

  const issuedCertifications = projectCertifications.filter(c => c.chain === chain)
  const missingCertifications = latestVersionFiles.filter(f => !fileCertificationsByChain[chain]?.includes(f.name))
  const allCertified = latestVersionFiles.every(f => fileCertificationsByChain[chain]?.includes(f.name))

  const canRequestCertifications = !!Object.values(fileAddresses).length

  const requestCertificationsHandler = () => {
    if (!Object.values(fileAddresses).length) return
    requestCertifications({
      chain,
      fileAddresses,
    })
  }

  return (
    <div className="w-full h-full py-10 pl-10 pr-16">
      <RequestCertificationsComponent />
      <div className="flex flex-col h-full">
        <div className="flex items-center gap-2">
          <button
            onClick={() => navigate(`/audit/project/${project?.id}`)}
            className="flex items-center justify-center p-2 rounded-full hover:bg-bg-secondary transition-colors"
          >
            <BackIcon className="w-5 h-5 text-white" />
          </button>
          <span className="font-medium text-xl text-white">
            {supportedChainsConfig.find(c => c.id === chain)?.name} {project?.name || "..."} Certifications
          </span>
        </div>
        <div className="w-full h-full overflow-y-auto mt-10">
          {!allCertified && (
            <>
              <span className="font-medium text-sm text-white">
                Missing Certifications
              </span>
              <div className="flex flex-col gap-3 mt-3 mb-4">
                {missingCertifications.map((chain, i) => {
                  return (
                    <div
                      key={i}
                      className="bg-bg-secondary text-white rounded-2.5 px-5 py-3 border flex items-center gap-6
                   border-border-primary"
                    >
                      <span className="text-left">{chain.name}</span>
                      <input
                        className="rounded-2.5 focus:border outline-none grow border-border-primary p-4 bg-black text-white"
                        type="text"
                        placeholder="Onchain address"
                        value={fileAddresses[chain.name] || ""}
                        onChange={e => setFileAddresses({
                          ...fileAddresses,
                          [chain.name]: e.target.value,
                        })}
                      />
                    </div>
                  )
                })}
                <button
                  onClick={requestCertificationsHandler}
                  data-tooltip-id="request-certifications"
                  className="bg-white  transition-all hover:bg-slate-100 flex items-center gap-3 w-max self-center
                 py-4 px-8 rounded-full text-black relative mt-4"
                >
                  <span>Request Certifications</span>
                </button>
                {!canRequestCertifications && (
                  <Tooltip id="request-certifications">
                    <span>Enter an onchain address to continue</span>
                  </Tooltip>
                )}
              </div>
            </>)}
          <span className="font-medium text-sm text-white">
            Issued Certifications
          </span>
          <div className="flex flex-col gap-3 mt-3">
            {!issuedCertifications.length && (
              <span className="text-white bg-bg-secondary border border-border-primary flex flex-col p-10 rounded-2.5 items-center">
                No certifications issued
              </span>
            )}
            {issuedCertifications.map((chain, i) => {
              return (
                <button
                  key={i}
                  className="bg-bg-secondary text-white rounded-2.5 px-5 py-3 border flex items-center gap-4
                   border-border-primary transition-colors"
                >
                  <span className="grow text-left">{chain.fileName}</span>
                  <button
                    onClick={() => navigate(`/audit/certifications/${chain.publicId}`)}
                    className="bg-white  transition-all hover:bg-slate-100 flex items-center gap-3 w-max self-center
                   py-4 px-8 rounded-full text-black relative"
                  >
                    View Certification
                  </button>
                </button>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectNetworkCertificationsPage
