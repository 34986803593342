import { AxiosInstance } from "axios"
import axios from "./axios"
import { ICertification } from "types/certifications"

class CertificationsApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  async getCertifications(id: string) {
    return this.axios.get(`/certifications/${id}`).then(res => res.data as ICertification)
  }
}

const certificationsApi = new CertificationsApi()

export default certificationsApi
