import useAwaitableModal from "hooks/useAwaitableModal"
import { FC, useCallback } from "react"
import GoPlusTokenSecurity from "./modals/GoPlus"
import { GoPlusTokenSecurityParams } from "types/goplus"
import { GoPlusSrc } from "assets/images"

interface props {
    params?: GoPlusTokenSecurityParams
}

const GoPlusButton: FC<props> = ({ params }) => {
  const [_show, GoPlusOverview] = useAwaitableModal(GoPlusTokenSecurity, { params } as any)
  const show = useCallback(() => {
    _show({ params })
  }, [_show, params])

  if (!params) return null

  return (
    <>
      <GoPlusOverview />
      <button
        onClick={show}
        className="text-white border
                    bg-gradient-to-t from-[#7739C5] to-[#974DDA]
                    border-transparent
                    hover:border-border-primary
                    hover:opacity-90
                    transition-all
                    flex items-center
                    px-4 py-2 rounded-lg mr-4"
      >
        {/*<img src={GoPlusSrc} alt="GoPlus" className="h-5 invert" />*/}
        <span className="ml-2 mt-1">Extra Info</span>
      </button>
    </>
  )
}

export default GoPlusButton
