import { FirebaseOptions, initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import { getMessaging } from "firebase/messaging"
import { getAnalytics } from "firebase/analytics"

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyC5x8S7wxTrQ-l2ELDLKwlzT---jhb6jpw",
  authDomain: "sentry-62b42.firebaseapp.com",
  projectId: "sentry-62b42",
  storageBucket: "sentry-62b42.appspot.com",
  messagingSenderId: "703088256822",
  appId: "1:703088256822:web:2f051db38d27df602b7597",
  measurementId: "G-7KH810F308",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)
const messaging = getMessaging(app)
const analytics = getAnalytics(app)

const firebase = { app, auth, db, storage, messaging, analytics }

export default firebase
