import { AxiosInstance } from "axios"
import axios from "./axios"
import { IContractAudit } from "types/audits"

class AuditsApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  async getContractAudit(address: string, chain: string) {
    // long running operation
    const baseUrl = "https://us-central1-sentry-62b42.cloudfunctions.net/api"

    return this.axios.get(`${baseUrl}/audits/contract/${chain}/${address}`).then(res => res.data as IContractAudit)
  }
}

const auditsApi = new AuditsApi()

export default auditsApi
