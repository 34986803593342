import { BackIcon } from "assets/icons"
import Loader from "components/Loader"
import { supportedChainsConfig } from "constants/chains"
import { useProject } from "contexts/Project"
import { FC } from "react"
import { useNavigate } from "react-router"

const ProjectCertificationsPage: FC = () => {
  const { project, certifications, latestVersionData } = useProject()
  const navigate = useNavigate()

  if (!certifications || !latestVersionData) return (
    <div className="w-full h-full overflow-hidden bg-black">
      <Loader />
    </div>
  )

  const projectCertifications = Object.values(certifications)
  const latestVersionFiles = latestVersionData.files
  const fileCertificationsByChain = projectCertifications.reduce((acc, cert) => {
    return {
      ...acc,
      [cert.chain]: [...(acc[cert.chain] || []), cert.fileName],
    }
  }, {} as Record<string, string[]>)

  return (
    <div className="w-full h-full py-10 pl-10 pr-16">
      <div className="flex flex-col h-full">
        <div className="flex items-center gap-2">
          <button
            onClick={() => navigate(`/audit/project/${project?.id}`)}
            className="flex items-center justify-center p-2 rounded-full hover:bg-bg-secondary transition-colors"
          >
            <BackIcon className="w-5 h-5 text-white" />
          </button>
          <span className="font-medium text-xl text-white">
            Manage certifications for {project?.name || "..."}
          </span>
        </div>
        <span className="font-medium text-sm text-white mt-10">
          Network
        </span>
        <div className="w-full h-full overflow-y-auto mt-3">
          <div className="flex flex-col gap-3">
            {supportedChainsConfig.map((chain, i) => {
              const allCertified = latestVersionFiles.every(f => fileCertificationsByChain[chain.id]?.includes(f.name))

              return (
                <button
                  key={i}
                  onClick={() => navigate(`/audit/project/${project?.id}/certifications/${chain.id}`)}
                  className="bg-bg-secondary text-white rounded-2.5 px-5 py-6 border flex items-center gap-4
                   border-border-primary hover:border-transparent hover:opacity-90 transition-colors"
                >
                  <div
                    className="w-8 h-8 rounded-full [&>svg]:w-8 [&>svg]:h-8"
                    dangerouslySetInnerHTML={{ __html: chain.svg }}
                  />
                  <span className="grow text-left">{chain.name}</span>
                  {!allCertified ? (
                    <span
                      className="text-sm"
                    >
                      {fileCertificationsByChain[chain.id]?.length || 0} / {latestVersionFiles.length} files certified
                    </span>
                  ) : (
                    <span
                      className="text-sm text-green-400"
                    >
                      All files certified
                    </span>
                  )}
                </button>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectCertificationsPage
