import projectsApi from "api/projects"
import Loader from "components/Loader"
import { Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useCallback, useContext, useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate } from "react-router"
import { IProject, IProjectFileAudit, IProjectNetworkContractCertification } from "types/projects"
import { useAuth } from "./Auth"
import toasts from "components/toasts"

interface ProjectContextType {
    project?: IProject
    setProject: Dispatch<SetStateAction<IProject | undefined>>
    version?: string;
    setVersion: (version: string) => void
    versionData?: {
        id: string
        createdAt: string
        files: {
            name: string
            audit?: IProjectFileAudit
        }[]
    }
    latestVersionData?: {
        id: string
        createdAt: string
        files: {
            name: string
            audit?: IProjectFileAudit
        }[]
    }
    file?: string
    setFile: Dispatch<SetStateAction<string | undefined>>
    certifications: IProjectNetworkContractCertification[]
    onCertify: (
        chainId: string,
        fileAddresses: Record<string, string>,
        compilerSettings: { solcVersion: string, optimized: boolean, runs: number },
    ) => void
    certificationInProgress: boolean
}

export const ProjectContext = createContext<ProjectContextType>({} as ProjectContextType)

export const useProject = () => useContext(ProjectContext)

const ProjectProvider: FC<PropsWithChildren> = ({ children }) => {
  const user = useAuth().user
  const location = useLocation().pathname
  const [,,, id, version] = location.split("/")
  const [file, setFile] = useState<string>()
  const [project, setProject] = useState<IProject>()
  const [versions, setVersions] = useState({} as Record<string, {
    id: string
    createdAt: string
    files: {
      name: string
      audit?: IProjectFileAudit
    }[]
  }>)
  const [certifications, setCertifications] = useState<IProjectNetworkContractCertification[]>([])
  const navigate = useNavigate()
  const [certificationInProgress, setCertificationInProgress] = useState(false)

  useEffect(() => {
    if (!id) return
    projectsApi.getProject(id).then(setProject)
  }, [id])

  useEffect(() => {
    if (!project?.id) return
    if (!version) return
    if (Number.isNaN(parseInt(version))) return
    projectsApi.getVersion(project.id, parseInt(version))
      .then(data => {
        setVersions(prev => ({
          ...prev,
          [version]: {
            ...data,
            files: data.files.map(f => ({ name: f, audit: undefined })),
          },
        }))
      })
  }, [project?.id, version])

  useEffect(() => {
    if (!project?.id) return
    if (!version) return
    if (!file) return
    if (Number.isNaN(parseInt(version))) return
    projectsApi.getAudit(project.id, parseInt(version), file)
      .then(audit => {
        setVersions(prev => ({
          ...prev,
          [version]: {
            ...prev[version],
            files: prev[version].files.map(f => f.name === file ? { ...f, audit } : f),
          },
        }))
      })
  }, [project?.id, version, file])

  useEffect(() => {
    if (!project?.id) return
    if (project?.currentVersion === undefined) return
    const latestVersion = project.currentVersion.toString()
    projectsApi.getVersion(project.id, project.currentVersion)
      .then(data => {
        setVersions(prev => ({
          ...prev,
          [latestVersion]: {
            ...data,
            files: data.files.map(f => ({ name: f, audit: prev[latestVersion]?.files.find(fprev => fprev.name === f)?.audit })),
          },
        }))
      })
    navigate(`/audit/project/${project.id}/${project.currentVersion}`)
  }, [project?.currentVersion, project?.id])

  useEffect(() => {
    if (!id) return
    if (project?.currentVersion === undefined) return
    projectsApi.getCertifications(id).then(setCertifications).catch(err => err.response?.data?.error)
  }, [id, project?.currentVersion])

  useEffect(() => {
    const versionData = version ? versions[version] : undefined
    if (!versionData) return
    if (!versionData.files.find(f => f.name === file) && versionData.files[0]) {
      setFile(versionData.files[0].name)
    }
  }, [version, versions, file])

  const onCertify = useCallback((
    chainId: string,
    fileAddresses: Record<string, string>, compilerSettings: { solcVersion: string, optimized: boolean, runs: number },
  ) => {
    if (!project) return
    if (certificationInProgress) return
    setCertificationInProgress(true)
    projectsApi.certify(project.id, chainId, fileAddresses, compilerSettings)
      .then(({ fulfilled, failed }) => {
        setCertifications(prev => [...prev, ...fulfilled])
        const errors = failed.map(({ error }) => error)
        if (errors.length) toasts.error(errors.join(", "))
      })
      .catch(err => toasts.error(err.response?.data?.error))
      .finally(() => setCertificationInProgress(false))
  }, [certificationInProgress, project?.id])

  const setVersion = useCallback((version: string) => {
    navigate(`/audit/project/${project?.id}/${version}`)
  }, [project?.id])

  useEffect(() => {
    if (!project?.currentVersion) return
    if (version) return
    setVersion(project.currentVersion.toString())
  }, [project?.currentVersion, version])

  if (!user) return <Navigate to="/audit" replace />

  if (!project) return (
    <div className="w-full h-full overflow-hidden bg-black">
      <Loader />
    </div>
  )

  return (
    <ProjectContext.Provider
      value={{
        project,
        setProject,
        version,
        setVersion,
        versionData: version ? versions[version] : undefined,
        latestVersionData: project.currentVersion ? versions[project.currentVersion.toString()] : undefined,
        file,
        setFile,
        certifications,
        onCertify,
        certificationInProgress,
      }}
    >
      {certificationInProgress && (
        <div className="absolute top-0 left-0 w-full h-full bg-black z-10 flex flex-col items-center justify-center gap-4">
          <Loader />
        </div>
      )}
      {children}
    </ProjectContext.Provider>
  )
}

export default ProjectProvider
