import projectsApi from "api/projects"
import Modal from "components/Modal"
import { useProject } from "contexts/Project"
import { AwaitableModal } from "hooks/useAwaitableModal"
import { useRef, useState } from "react"

const UploadVersionModal: AwaitableModal = ({ resolve, open }) => {
  const { project, setProject } = useProject()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const fileInput = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<File>()

  const removeFile = () => {
    setFile(undefined)
    fileInput.current?.value && (fileInput.current.value = "")
    fileInput.current?.files && (fileInput.current.files = null)
  }

  const create = async() => {
    if (!file) return
    if (submitting) return
    setSubmitting(true)
    projectsApi.uploadVersion(project?.id as string, file).then(version => {
      setProject(old => old && ({ ...old, currentVersion: version }))
      resolve(true)
    }).finally(() => {
      setSubmitting(false)
    })
  }

  return (
    <Modal open={open} close={() => resolve(false)}>
      <div className="w-full h-max p-10 rounded-2.5 bg-bg-secondary border border-border-primary">
        <div className="flex flex-col items-center justify-center h-full gap-6 grow">
          <h1 className="text-2xl font-semibold text-white">Upload a Version</h1>
          <input
            ref={fileInput}
            type="file"
            id="version-upload"
            className="rounded-full border hidden border-border-primary py-4 px-8 bg-bg-secondary outline-none text-white text-center"
            placeholder="Upload Zip File"
            accept=".zip"
            onChange={e => setFile(e.target.files?.[0])}
          />
          {file ? (
            <>
              <div className="flex items-center gap-6 min-w-[400px] bg-bg-primary py-3 px-5 rounded-2.5 border border-border-primary">
                <span className="text-white grow">{file.name}</span>
                <button
                  onClick={removeFile}
                  className="px-4 py-2 rounded-full bg-black border border-border-primary hover:bg-bg-secondary transition-colors"
                >
                  <span className="text-base text-white">Remove</span>
                </button>
              </div>
              <button
                onClick={create}
                disabled={submitting}
                className="px-16 py-4 rounded-full bg-black border border-border-primary hover:bg-bg-secondary transition-colors"
              >
                <span className="text-base text-white">{submitting ? "..." : "Upload"}</span>
              </button>
            </>
          ): (
            <>
              <span className="text-text-secondary text-base">
                Upload a zip file of flattened contracts (contracts with all imports inlined)
              </span>
              <label
                htmlFor="version-upload"
                onClick={() => setFile(undefined)}
                className="px-16 py-4 rounded-full cursor-pointer
               bg-black border border-border-primary hover:bg-bg-secondary transition-colors"
              >
                <span className="text-base text-white">Choose File</span>
              </label>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default UploadVersionModal
