import projectsApi from "api/projects"
import Loader from "components/Loader"
import { useAuth } from "contexts/Auth"
import useAwaitableModal from "hooks/useAwaitableModal"
import useTitle from "hooks/useTitle"
import { FC, useEffect, useState } from "react"
import { IProject } from "types/projects"
import CreateProjectModal from "./modals/CreateProject"
import { useNavigate } from "react-router"

const ProjectAuditPage: FC = () => {
  const auth = useAuth()
  const [projects, setProjects] = useState<IProject[]>()
  const [error, setError] = useState<string | null>()
  const [createProject, CreateProjectComponent] = useAwaitableModal(CreateProjectModal, {})
  const navigate = useNavigate()
  const [authRequired, setAuthRequired] = useState<boolean>(false)
  useTitle("Project Audits")

  const onCreateProject = () => {
    if (!auth.user) return setAuthRequired(true)
    createProject()
  }

  useEffect(() => {
    if (!auth.user) return setProjects([])
    projectsApi.getProjects().then(setProjects).catch(e => setError(e.message))
  }, [auth.user])

  const prettyStatus = (status: string) => {
    switch (status.toLowerCase()) {
    case "pending": return "Pending"
    case "in_progress": return "In Progress"
    case "completed": return "Completed"
    default: return status
    }
  }

  if (authRequired && !auth.user) return (
    <div className="w-full h-full py-10 pl-10 pr-16">
      <div className="flex flex-col items-center justify-center h-full gap-6 grow bg-bg-secondary rounded-2.5 border border-border-primary">
        <h1 className="text-4xl font-semibold text-white">This is an authorized area</h1>
        <span className="text-text-secondary text-base">
          You need to connect your wallet to continue
        </span>
      </div>
    </div>
  )

  if (error) return (
    <div className="w-full h-full py-10 pl-10 pr-16">
      <div className="flex flex-col items-center justify-center h-full gap-6 grow bg-bg-secondary rounded-2.5 border border-border-primary">
        <h1 className="text-4xl font-semibold text-white">Error</h1>
        <span className="text-text-secondary text-base">
          {error || "An error occurred"}
        </span>
        <button
          onClick={() => window.location.reload()}
          className="px-16 py-4 rounded-full bg-black border border-border-primary hover:bg-bg-secondary transition-colors"
        >
          <span className="text-base text-white">Reload</span>
        </button>
      </div>
    </div>
  )

  if (!projects) return (
    <div className="w-full h-full overflow-hidden bg-black">
      <Loader />
    </div>
  )

  return (
    <div className="w-full h-full pt-10 pl-10 pr-16">
      <CreateProjectComponent />
      <div className="flex flex-col h-full">
        <div
          className="flex p-6 items-center gap-6 bg-bg-secondary rounded-2.5 border border-border-primary"
        >
          <h1 className="text-2xl font-semibold text-white grow">Project Audit</h1>
          <button onClick={onCreateProject} className="bg-white rounded-full py-4 px-12 hover:bg-slate-100 transition-colors" type="submit">
            <span className="text-black text-base">Create a Project</span>
          </button>
        </div>
        <div className="flex flex-col w-full grow overflow-y-auto pb-10">
          <span className="font-medium text-xl text-white mt-10 mb-6">
            View projects and submitted versions
          </span>
          {!!projects.length ? (
            <table className="border-separate border-spacing-x-none border-spacing-y-2.5">
              <thead className="[&_th]:text-white [&_th]:text-left [&_th]:font-medium [&_th]:font-sm [&_th]:pb-0.5s">
                <tr>
                  <th>
                    Project Name
                  </th>
                  <th>
                    Status
                  </th>
                  <th>
                    Version
                  </th>
                  <th>
                    Updated At
                  </th>
                </tr>
              </thead>
              <tbody
                className="[&_td]:text-white [&_td]:text-sm [&_td]:py-5 [&_td]:bg-[#090909] [&_td]:transition-colors
           [&_td:first-child]:rounded-l-2.5 [&_td:first-child]:pl-5
           [&_td:last-child]:pr-5 [&_td:last-child]:rounded-r-2.5"
              >
                {projects?.map(project => (
                  <tr
                    key={project.id}
                    onClick={() => navigate(`/audit/project/${project.id}`)}
                    className="border-b-8 cursor-pointer [&:hover_td]:bg-[#101010]"
                  >
                    <td>
                      {project.name}
                    </td>
                    <td>
                      {prettyStatus(project.status)}
                    </td>
                    <td>
                      {project.currentVersion || "No versions submitted"}
                    </td>
                    <td>
                      {new Date(project.updatedAt).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div
              className="flex flex-col items-center justify-center h-full gap-6
              grow bg-bg-secondary rounded-2.5 border border-border-primary"
            >
              <h1 className="text-2xl font-semibold text-white">No Projects Found</h1>
              <span className="text-text-secondary text-base">
                Create your first project to get started
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProjectAuditPage
