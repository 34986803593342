import { AxiosInstance } from "axios"
import axios from "./axios"
import { ChainPickerInput, ChainPickerOutput } from "components/modals/ChainPicker"

class AuthApi {
  api: AxiosInstance
  constructor(api: AxiosInstance) {
    this.api = axios
  }

  async getAddresses() {
    const addresses = await window.ethereum.request({ method: "eth_requestAccounts" })

    return addresses
  }

  async getChainId() {
    const chainId = await window.ethereum.request({ method: "eth_chainId" })

    return chainId
  }

  async signInWithEthereum(pickChain: (data: ChainPickerInput) => Promise<ChainPickerOutput>) {
    if (!window.ethereum && window.confirm("No Ethereum wallet detected. Do you want to install MetaMask?")) {
      window.open("https://metamask.io/download/", "_blank")
    }
    const currentChain = await this.getChainId()
    const addresses = await this.getAddresses()

    const { chain, address } = await pickChain({ chain: currentChain, addresses })

    if (chain !== currentChain) {
      await window.ethereum.request({ method: "wallet_switchEthereumChain", params: [{ chainId: chain }] })
    }

    const messageParts = []
    messageParts.push(["Sign in to SentryAI"])
    messageParts.push(["Chain:", chain].join(" "))
    messageParts.push(["Address:", address].join(" "))
    messageParts.push(["Issued At:", new Date().toISOString()].join(" "))
    messageParts.push(["Expires At:", new Date(Date.now() + 1000 * 60 * 5).toISOString()].join(" "))
    messageParts.push(["Nonce:", Math.random().toString()].join(" "))
    const message = messageParts.join("\n")
    const signature = await window.ethereum.request({ method: "personal_sign", params: [message, address] })
    const token = await this.api.post("/auth/siwe/verify", { signature, message }).then(res => res.data.customToken as string)

    return token
  }

  async getExternalTokenQuery() {
    return this.api.post("/auth/external").then(res => res.data as string)
  }
}

const authApi = new AuthApi(axios)

export default authApi
