import {
  PropsWithChildren,
  createContext, forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState,
} from "react"
import { IUser } from "types/user"
import { useAuth } from "./Auth"
import userApi from "api/user"

type UserContextType = {
    user?: IUser | undefined;
};

export const UserContext = createContext({} as UserContextType)

export const useUser = () => useContext(UserContext)

const UserProvider = forwardRef<UserContextType, PropsWithChildren>(({ children }, ref) => {
  const [user, setUser] = useState<IUser>()
  const auth = useAuth()

  useEffect(() => {
    if (auth.user) {
      userApi.getUser().then(setUser)
    } else {
      setUser(undefined)
    }
  }, [auth.user])

  const value = useMemo(() => ({ user }), [user])

  useImperativeHandle(ref, () => value, [value])

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  )
})

export default UserProvider

