import Loader from "components/Loader"
import { useProject } from "contexts/Project"
import { FC } from "react"
import { Tooltip } from "react-tooltip"

const categories = [
  "Arbitrary Jump/Storage Write",
  "Centralization of Control",
  "Compiler Issues",
  "Delegate Call to Untrusted Contract",
  "Dependence on Predictable Variables",
  "Ether/Token Theft",
  "Flash Loans",
  "Front Running",
  "Improper Events",
  "Improper Authorization Scheme",
  "Integer Over/Underflow",
  "Logical Issues",
  "Oracle Issues",
  "Outdated Compiler Version",
  "Race Conditions",
  "Reentrancy",
  "Signature Issues",
  "Sybil Attack",
  "Unbounded Loops",
  "Unused Code",
  "Overall Contract Safety",
]

const Analysis: FC = () => {
  const { versionData, file } = useProject()
  const fileObj = versionData?.files.find(f => f.name === file)

  const failuresGrouped = fileObj?.audit?.output?.result?.failures.reduce((acc, f) => ({
    ...acc,
    [f.name]: [...(acc[f.name] || []), f.reason],
  }), {} as Record<string, string[]>)

  const failed = Object.keys(failuresGrouped || {}).filter(k => categories.includes(k)).length
  const passed = categories.length - failed

  if (versionData?.files && !versionData.files.length) return (
    <div
      className="w-full h-full"
    >
      <div
        className="flex flex-col items-center justify-center h-full py-16
       gap-6 grow bg-bg-secondary rounded-2.5 border border-border-primary"
      >
        <h1 className="text-2xl font-semibold text-white">No Files Found</h1>
        <span className="text-text-secondary text-base">
          Uploaded version does not contain any .sol files
        </span>
      </div>
    </div>
  )

  if (!fileObj?.audit) return (
    <div
      className="w-full h-full flex flex-col items-center gap-6
       overflow-hidden bg-black relative rounded-2.5 border border-border-primary py-10"
    >
      <Loader />
      <span className="text-center">
        <span className="text-white">Analyzing</span>
        <span className="text-[#D0D0D0]"> {file}</span>
      </span>
    </div>
  )

  if (fileObj.audit.error) return (
    <div
      className="w-full h-full"
    >
      <div
        className="flex flex-col items-center justify-center h-full py-10
       gap-6 grow bg-bg-secondary rounded-2.5 border border-border-primary"
      >
        <h1 className="text-4xl font-semibold text-white">Error</h1>
        <span className="text-text-secondary text-base">
          {fileObj.audit.error || "An error occurred"}
        </span>
        <button
          onClick={() => window.location.reload()}
          className="px-16 py-4 rounded-full bg-black border border-border-primary hover:bg-bg-secondary transition-colors"
        >
          <span className="text-base text-white">Reload</span>
        </button>
      </div>
    </div>
  )

  return (
    <div
      className="flex flex-col items-center justify-center h-full mb-10 px-16 py-6
         gap-6 grow bg-bg-secondary rounded-2.5 border border-border-primary"
    >
      <div className="w-full flex items-center border-b border-b-[#282828] pb-4">
        <span className="text-xl text-white font-medium grow">
          Vulnerability Check
        </span>
        <div className="flex items-center gap-6">
          <div className="flex items-center gap-2">
            <div className="p-3 rounded-full bg-[#26CF361A] animate-pulse">
              <div className="w-4 h-4 rounded-full bg-[#26CF36]" />
            </div>
            <span className="text-[#D0D0D0]">
              {passed} Passed
            </span>
          </div>
          <div className="flex items-center gap-2">
            <div className="p-3 rounded-full bg-[#D000191A] animate-pulse">
              <div className="w-4 h-4 rounded-full bg-[#D00019]" />
            </div>
            <span className="text-[#D0D0D0]">
              {failed} Failed
            </span>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
        {categories.map((category, i) => {
          const failure = failuresGrouped?.[category]
          const failureText = failure?.join("\n\n")
          const bgColor = failure ? "#D000190D" : "#26CF360D"
          const dotColor = failure ? "#D00019" : "#26CF36"

          return (
            <div
              key={i}
              className="flex items-center w-full p-2 rounded-full"
              style={{ backgroundColor: bgColor }}
            >
              <Tooltip id={category} className="z-[10] tooltip-vulnerability" place="top">
                <div className="flex flex-col max-w-64">
                  <span className="text-[#D0D0D0] text-sm whitespace-pre-line">
                    {failure ? failureText : "No issues found"}
                  </span>
                </div>
              </Tooltip>
              <div data-tooltip-id={category} className="p-3 rounded-full animate-pulse">
                <div className="w-4 h-4 rounded-full" style={{ backgroundColor: dotColor }} />
              </div>
              <span className="text-[#D0D0D0] text-base">{category}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Analysis
