import { useAuth } from "contexts/Auth"
import { FC } from "react"
import { Navigate, Outlet, Route, Routes } from "react-router"
import MainLayout from "layouts/Main"
import AuthorizeVSCodePage from "pages/private/vscode/authorize"
import AIEnginePage from "pages/private/AIEngine"
import ContractAuditPage from "pages/private/Audit/ContractAudit"
import ContractAuditDetailPage from "pages/private/Audit/ContractAuditDetail"
import ProjectAuditPage from "pages/private/Audit/ProjectAudit"
import ProjectPage from "pages/private/Audit/ProjectAudit/Project"
import ProjectProvider from "contexts/Project"
import ProjectCertificationsPage from "pages/private/Audit/ProjectAudit/Certifications"
import ProjectNetworkCertificationsPage from "pages/private/Audit/ProjectAudit/Certifications/NetworkCertifications"
import CertificationPage from "pages/Certification"

const RootRouter: FC = () => {
  const auth = useAuth()

  if (auth.user === undefined) return <div>Loading ...</div>

  return (
    <>
      <div
        className="fixed inset-0 transition-all"
        style={
          auth.authInProgress
            ? {
              background: "rgba(0, 0, 0, 0.5)",
              pointerEvents: "auto",
            } : {
              background: "rgba(0, 0, 0, 0)",
              pointerEvents: "none",
            }
        }
      />
      <Routes>
        <Route path="*" element={<MainLayout />}>
          <Route index element={<AIEnginePage />} />
          <Route path="audit" element={<Outlet />}>
            <Route index element={<ContractAuditPage />} />
            <Route path="certifications">
              <Route index element={<Navigate to="/audit" replace />} />
              <Route path=":id" element={<CertificationPage />} />
            </Route>
            <Route path="project" element={<Outlet />}>
              <Route index element={<ProjectAuditPage />} />
              <Route
                path=":projectId"
                element={<ProjectProvider><Outlet /></ProjectProvider>}
              >
                <Route path="certifications" >
                  <Route index element={<ProjectCertificationsPage />} />
                  <Route path=":chain" element={<ProjectNetworkCertificationsPage />} />
                </Route>
                <Route path="*" element={<ProjectPage />} />
              </Route>
            </Route>
            <Route path=":chain" element={<Outlet />}>
              <Route index element={<Navigate to="/audit" replace />} />
              <Route path=":contract" element={<ContractAuditDetailPage />} />
            </Route>
          </Route>
          <Route path="vscode" element={<Outlet />}>
            <Route index element={<div>VSCode</div>} />
            <Route path="authorize" element={<AuthorizeVSCodePage />} />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </>
  )
}

export default RootRouter
