import { Logo } from "assets"
import {
  AIEngineIcon,
  AuditIcon, ContractAuditIcon, ProjectAuditIcon, LeaderboardsIcon, LiveMonitoringIcon, NoCodeIcon,
} from "assets/icons"
import { XSocialIcon, TelegramSocialIcon, GitBookSocialIcon, FourthSocialIcon } from "assets/images/social"
import { FC, PropsWithChildren, useState } from "react"
import { useLocation } from "react-router"
import { Link } from "react-router-dom"

const SubSection: FC<PropsWithChildren<{ open: boolean }>> = ({ children, open }) => {
  const [height, setHeight] = useState(0)

  return (
    <div
      ref={e => setHeight(e?.scrollHeight || 0)}
      className="-mt-4 relative"
      style={{
        height: open ? height : 0,
        overflow: "hidden",
        transition: "height 0.3s",
      }}
    >
      {children}
    </div>
  )
}

const Sidebar: FC = () => {
  const location = useLocation()

  const isActive = (route: string) => {
    if (route === "/") return location.pathname === route

    return location.pathname.startsWith(route)
  }

  const routes = [
    {
      name: "AI Engine",
      path: "/",
      icon: AIEngineIcon,
    },
    {
      name: "Audit",
      path: "/audit",
      icon: AuditIcon,
      subpaths: [
        {
          name: "Contract Audit",
          path: "/audit",
          icon: ContractAuditIcon,
        },
        {
          name: "Project Audit",
          path: "/audit/project",
          icon: ProjectAuditIcon,
        },
      ],
    },
    {
      name: "Leaderboards",
      path: "/leaderboards",
      icon: LeaderboardsIcon,
      comingSoon: true,
    },
    {
      name: "Live Monitoring",
      path: "/live-monitoring",
      icon: LiveMonitoringIcon,
      comingSoon: true,
    },
    {
      name: "No Code",
      path: "/no-code",
      icon: NoCodeIcon,
      comingSoon: true,
    },
  ]

  return (
    <div className="w-[16rem] bg-bg-primary flex flex-col h-full items-center gap-4 relative overflow-hidden">
      <div className="h-24 py-5 w-full flex items-center justify-center border-b border-b-border-primary">
        <Logo className="h-full" />
      </div>
      <div className="flex flex-col grow overflow-y-auto w-full px-4 gap-2">
        {routes.map((route, i) => (
          <div className="relative w-full">
            <Link
              key={i}
              to={route.path}
              className={[
                "flex items-center gap-3 p-4 w-full rounded-lg transition-all opacity-100 relative group z-[1]",
                route.comingSoon && "pointer-events-none",
              ].asClass}
            >
              <route.icon className="w-5 h-5 z-[1]" />
              <div className="flex flex-col relative z-[1]">
                <span className="text-white text-sm">{route.name}</span>
                {route.comingSoon && (
                  <span
                    className="text-[0.6125rem] bg-[rgba(151,77,218,0.1)] rounded-md w-max py-1 px-2
                   text-[#974DDA] absolute -bottom-1 translate-y-full"
                  >
                    COMING SOON
                  </span>
                )}
              </div>
              <div
                className={
                  [
                    "absolute inset-0 bg-gradient-to-t from-[#7739C5] to-[#974DDA]",
                    "opacity-0 group-hover:opacity-100 rounded-lg transition-all",
                    isActive(route.path) && "opacity-100 group-hover:opacity-[0.9]",
                  ].asClass
                }
              />
            </Link>
            {route.subpaths && (
              <SubSection open={isActive(route.path)}>
                <div className="flex flex-col gap-0.5 bg-[#181818] rounded-2.5 pt-6 py-2">
                  {route.subpaths.map((subpath, j) => (
                    <Link
                      key={subpath.name}
                      to={subpath.path}
                      className="flex items-center gap-3 px-6 py-2 w-full rounded-lg transition-all text-white text-sm"
                    >
                      <subpath.icon className="w-5 h-5" />
                      <span
                        className={[
                          "transition-all font-normal text-[#B8B8B8] hover:text-white",
                          subpath.path === location.pathname && "font-bold !text-white",
                        ].asClass}
                      >{subpath.name}
                      </span>
                    </Link>
                  ))}
                </div>
              </SubSection>
            )}
          </div>
        ))}
      </div>
      <div className="flex flex-col w-full gap-2 px-4 items-center pb-4 z-10">
        <div className="flex items-center p-2 gap-3 border border-border-primary rounded-2.5">
          <a
            href="https://twitter.com/sentry_ai"
            target="_blank"
            rel="noopener noreferrer"
            className="w-10 h-10 rounded-2.5 border border-border-primary cursor-pointer
             flex items-center justify-center hover:bg-bg-primary transition-colors"
          >
            <XSocialIcon className="pointer-events-none" />
          </a>
          <a
            href="https://t.me/sentry_ai"
            target="_blank"
            rel="noopener noreferrer"
            className="w-10 h-10 rounded-2.5 border border-border-primary cursor-pointer
             flex items-center justify-center hover:bg-bg-primary transition-colors"
          >
            <TelegramSocialIcon />
          </a>
          <a
            href="https://docs.sentryai.xyz/home"
            target="_blank"
            rel="noopener noreferrer"
            className="w-10 h-10 rounded-2.5 border border-border-primary cursor-pointer
             flex items-center justify-center hover:bg-bg-primary transition-colors"
          >
            <GitBookSocialIcon />
          </a>
          <div className="w-10 h-10 rounded-2.5 flex items-center justify-center border border-border-primary relative overflow-hidden">
            <FourthSocialIcon />
            <div
              className="absolute inset-0 cursor-default hover:opacity-100 opacity-0 transition-all flex items-center justify-center"
              style={{ background: "linear-gradient(180deg, rgba(151, 77, 218, 0.8) 0%, rgba(119, 57, 197, 0.8) 100%)" }}
            >
              <span className="text-white text-[0.5rem] leading-none text-center leading-[110%]">COMING SOON</span>
            </div>
          </div>
        </div>
        <span className="text-[0.6125rem] leading-[120%] text-white max-w-max text-center">
          built by&nbsp;
          <a href="https://tomorrow.biz" target="_blank" rel="noopener noreferrer" className="text-[#974DDA] hover:underline">
            Tomorrow.biz
          </a>
        </span>
      </div>
      <div className="bg-[rgba(255,255,255,0.2)] w-64 h-64 blur-[92px] absolute -left-4 -translate-x-[50%] bottom-16" />
    </div>
  )
}

export default Sidebar
