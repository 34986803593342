import useTitle from "hooks/useTitle"
import { FC } from "react"
import Overview from "./components/Overview"

const AIEnginePage: FC = () => {
  useTitle("AI Engine")
  const openCopilot = () => {
    window.open("https://marketplace.visualstudio.com/items?itemName=sentry-ai.SentryAI", "_blank", "noopener")
  }

  const submitFeedback = () => {
    console.log("submit feedback")
  }

  return (
    <div className="w-full h-full py-10 pl-10 pr-16">
      <div className="flex flex-col h-full gap-10">
        <Overview />
        <div
          className="flex flex-col items-center justify-center h-full
         gap-6 grow bg-bg-secondary rounded-2.5 border border-border-primary"
        >
          <h1 className="text-4xl font-semibold text-white flex items-center">AI Engine
            <span className="px-4 py-1 rounded-full bg-[#7739c5] text-sm ml-2">v1</span>
          </h1>

          <span className="text-text-secondary text-base">
            The AI Engine is a powerful tool behind Sentry AI. Get a peek.
          </span>
          <div className="flex items-center gap-4">
            <button
              onClick={openCopilot}
              className="bg-white text-black hover:bg-slate-100 transition-colors text-base
              px-8 border border-transparent hover:border-black py-5 rounded-full"
            >
              Download VS Copilot
            </button>
            <button
              onClick={submitFeedback}
              className="bg-gradient-to-t to-[rgba(255,255,255,0.03)] from-[rgba(255,255,255,0.27)]
            text-white transition-colors text-base group
              px-8 border border-[rgba(255,255,255,0.20)] py-5 rounded-full relative"
            >
              <div className="group-hover:opacity-100 opacity-0 bg-bg-primary transition-all absolute inset-0 rounded-full" />
              <span className="z-1 relative">Submit Feedback</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AIEnginePage
