import { AxiosInstance } from "axios"
import axios from "./axios"

class UserApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  async getUser() {
    return this.axios.get("/user").then(res => res.data as any)
  }
}

const userApi = new UserApi()

export default userApi
