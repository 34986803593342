import { LinkBulkIcon } from "assets/icons"
import { supportedChainsConfig } from "constants/chains"
import useTitle from "hooks/useTitle"
import { FC, FormEvent, useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { Tooltip } from "react-tooltip"

const ContractAuditPage: FC = () => {
  useTitle("Contract Audit")
  const [selectedChain, setSelectedChain] = useState<string>(supportedChainsConfig[0].id)
  const [address, setAddress] = useState<string>("")
  const [link, setLink] = useState<string>("")
  const navigate = useNavigate()

  useEffect(() => {
    const matchedLink = supportedChainsConfig.find(c => link.startsWith(c.scanner))
    if (matchedLink) setSelectedChain(matchedLink.id)
  }, [link])

  const submitAddress = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    navigate(`/audit/${selectedChain}/${address}`)

    return false
  }, [address, selectedChain])

  const submitLink = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const address = link.match(/0x[a-fA-F0-9]{40}/)?.[0]
    if (!address) return alert("Invalid Etherscan link")
    navigate(`/audit/${selectedChain}/${address}`)

    return false
  }, [selectedChain, link])

  return (
    <div className="w-full h-full overflow-y-auto pb-10">
      <div className="w-full h-full pt-10 pl-10 pr-16">
        <div className="flex flex-col">
          <div
            className="flex flex-col items-center justify-center h-full mb-10
         gap-6 grow bg-bg-secondary rounded-2.5 border border-border-primary"
          >
            <div className="flex gap-6 w-full p-10 items-center">
              <span className="text-3xl font-semibold text-white grow">Let's get started</span>
              <div className="flex overflow-x-auto gap-1">
                {
                  supportedChainsConfig.map(({ name, svg, id }) => (
                    <>
                      <button
                        key={id}
                        data-tooltip-id={id}
                        onClick={e => {
                          e.currentTarget.scrollIntoView({
                            behavior: "smooth",
                            inline: "center",
                            block: "nearest",
                          })
                          setSelectedChain(id)
                        }}
                        className={`flex flex-col items-center justify-center
                    rounded-full [&>svg]:w-8 [&>svg]:h-8
                    border-4 hover:border-border-primary transition-colors
                  ${id === selectedChain ? "border- bg-black" : "border-transparent"}`}
                        dangerouslySetInnerHTML={{ __html: svg }}
                      />
                      <Tooltip id={id}>
                        <span>{name}</span>
                      </Tooltip>
                    </>
                  ))
                }
              </div>
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-6 pb-10 px-10 w-full">
              <div className="flex flex-col rounded-2.5 border border-border-primary grow p-6 gap-6 items-start">
                <LinkBulkIcon />
                <div className="flex flex-col gap-2">
                  <span className="text-xl text-white font-medium">
                    Contract Address
                  </span>
                  <span className="text-[#D0D0D0] text-base">
                    Write your contact address and let us handle the rest.
                  </span>
                </div>
                <form onSubmit={submitAddress} className="flex flex-col gap-2">
                  <input
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                    className="rounded-full border border-border-primary py-4 px-8 bg-bg-secondary outline-none text-white text-center"
                    placeholder="Enter Contract Address"
                  />
                  <button className="bg-white rounded-full py-4 px-8 hover:bg-slate-100 transition-colors" type="submit">
                    <span className="text-black text-base">Submit</span>
                  </button>
                </form>
              </div>
              <div className="flex flex-col rounded-2.5 border border-border-primary grow p-6 gap-6 items-start">
                <LinkBulkIcon />
                <div className="flex flex-col gap-2">
                  <span className="text-xl text-white font-medium">
                    Explorer Link
                  </span>
                  <span className="text-[#D0D0D0] text-base">
                    Provide Explorer link here and let us handle the rest.
                  </span>
                </div>
                <form onSubmit={submitLink} className="flex flex-col gap-2">
                  <input
                    value={link}
                    onChange={e => setLink(e.target.value)}
                    className="rounded-full border border-border-primary py-4 px-8 bg-bg-secondary outline-none text-white text-center"
                    placeholder="Enter Explorer Link"
                  />
                  <button className="bg-white rounded-full py-4 px-8 hover:bg-slate-100 transition-colors" type="submit">
                    <span className="text-black text-base">Submit</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContractAuditPage
