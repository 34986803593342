import { BackIcon, UploadIcon } from "assets/icons"
import { useProject } from "contexts/Project"
import useAwaitableModal from "hooks/useAwaitableModal"
import { FC, useMemo } from "react"
import { useNavigate } from "react-router"
import UploadVersionModal from "../modals/UploadVersion"
import Analysis from "./Analysis"

const ProjectPage: FC = () => {
  const { project, version, setVersion, versionData, setFile, file, certifications } = useProject()
  const versions = useMemo(() => {
    if (project?.currentVersion === undefined) return undefined
    const arr = new Array(project.currentVersion).fill(0).map((_, i) => i + 1)

    return arr
  }, [project?.currentVersion])
  const [uploadVersion, UploadVersionComponent] = useAwaitableModal(UploadVersionModal, {})
  const navigate = useNavigate()

  return (
    <div className="w-full h-full pt-10">
      <div className="flex flex-col h-full gap-10">
        <UploadVersionComponent />
        <div className="pl-10 pr-16">
          <div
            className="flex items-center relative shrink-0 gap-6
     overflow-hidden bg-gradient-to-t from-[#572DB0] to-[#0D071A] rounded-2.5 w-full p-3"
          >
            <div
              className="absolute w-[32rem] h-[32rem] rounded-full -top-32 -left-32 blur-[8rem]"
              style={{ background: "radial-gradient(50% 50% at 50% 50%, #E7C1FF 0%, #9A1AFF 100%)" }}
            />
            <div className="flex items-center w-full relative z-[1] gap-2">
              <button
                onClick={() => navigate("/audit/project")}
                className="flex items-center justify-center p-2 rounded-full hover:bg-bg-secondary transition-colors"
              >
                <BackIcon className="w-5 h-5 text-white" />
              </button>
              <span className="text-3xl text-white font-semibold">
                {project?.name}
              </span>
              {versions && (
                <select
                  className="bg-[#9A1AFF] outline-none border border-[#9861D4] px-2 py-1.5
                rounded-md text-white text-sm cursor-pointer w-24 ml-4"
                  value={version}
                  onChange={e => setVersion(e.target.value)}
                >
                  {versions.map(version => (
                    <option
                      key={version}
                      value={version}
                      className="bg-bg-secondary text-white px-2 py-1.5 selection:bg-white selection:text-black"
                      style={{ lineHeight: "1.5rem" }}
                    >
                      v{version}
                    </option>
                  ))}
                </select>
              )}
              <div className="grow" />
              {certifications && !certifications.length && (
                <button
                  onClick={uploadVersion}
                  className="bg-gradient-to-b from-[rgba(255,255,255,0.03)] transition-all border border-[rgba(255,255,255,0.2)]
                   hover:bg-[rgba(255,255,255,0.27)] flex items-center gap-3
                     to-[rgba(255,255,255,0.27)] py-4 px-8 rounded-full text-white relative"
                >
                  <UploadIcon />
                  <span>Upload New Version</span>
                </button>
              )}
              <button
                onClick={() => navigate(`/audit/project/${project?.id}/certifications`)}
                className="bg-white  transition-all hover:bg-slate-100 flex items-center gap-3
                 py-4 px-8 rounded-full text-black relative"
              >
                <span>Manage Certifications</span>
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col overflow-y-auto pr-16 pl-10">
          {project ? project.currentVersion === undefined ? (
            <div
              className="flex flex-col items-center py-10
               justify-center h-full gap-6 grow bg-bg-secondary rounded-2.5 border border-border-primary"
            >
              <h1 className="text-2xl font-semibold text-white">No Versions Uploaded</h1>
              <span className="text-text-secondary text-base">
                Upload a zip file to start auditing
              </span>
            </div>
          ) : (
            <div className="flex flex-col gap-6">
              <div className="flex w-full items-center px-2.5 gap-6">
                <span className="text-white text-2xl font-semibold grow">
                  Detailed Analysis
                </span>
                <div className="flex items-center gap-4 overflow-x-auto">
                  {
                    versionData?.files?.map((data, i) => (
                      <button
                        key={i}
                        onClick={() => setFile(data.name)}
                        className={
                          file === data.name
                            ? "px-12 py-4 rounded-full text-black text-center text-base bg-white hover:bg-slate-100 transition-colors"
                            : `px-12 py-4 rounded-full text-white text-center text-base
                             bg-bg-secondary hover:bg-black border border-border-primary transition-colors`
                        }
                      >
                        {data.name}
                      </button>
                    ))
                  }
                </div>
              </div>
              <Analysis />
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  )
}

export default ProjectPage
