import certificationsApi from "api/certifications"
import { BackIcon } from "assets/icons"
import Loader from "components/Loader"
import { FC, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { ICertification } from "types/certifications"
import Analysis from "./Analysis"
import { chainUrls, scannerConfig } from "constants/chains"
import { Logo } from "assets"
import toasts from "components/toasts"

const CertificationPage: FC = () => {
  const id = useParams().id as string
  const [certification, setCertification] = useState<ICertification>()
  const [error, setError] = useState<string>()
  const navigate = useNavigate()

  useEffect(() => {
    if (!id) return
    certificationsApi.getCertifications(id)
      .then(setCertification)
      .catch(err => setError(err.response.data.error || err.message || "An error occurred"))
  }, [id])

  const openScan = () => {
    const scanner = scannerConfig.find(s => s.id === certification?.chain)
    if (!scanner) return
    const link = `${scanner.website}address/${certification?.address}`
    window.open(link, "_blank")
  }

  const onShare = () => {
    const link = `${window.location.origin}/certification/${id}`
    navigator.clipboard.writeText(link)
    toasts.success("Link copied to clipboard")
  }

  if (error) return (
    <div className="w-full h-full py-10 pl-10 pr-16">
      <div className="flex flex-col items-center justify-center h-full gap-6 grow bg-bg-secondary rounded-2.5 border border-border-primary">
        <h1 className="text-4xl font-semibold text-white">Error</h1>
        <span className="text-text-secondary text-base">
          {error || "An error occurred"}
        </span>
        <button
          onClick={() => window.location.reload()}
          className="px-16 py-4 rounded-full bg-black border border-border-primary hover:bg-bg-secondary transition-colors"
        >
          <span className="text-base text-white">Reload</span>
        </button>
      </div>
    </div>
  )

  if (!certification) return (
    <div className="w-full h-full overflow-hidden bg-black">
      <Loader />
    </div>
  )

  return (
    <div className="w-full h-full pt-10">
      <div className="flex flex-col h-full gap-10">
        <div className="pl-10 pr-16">
          <div
            className="flex items-center relative shrink-0 gap-6
     overflow-hidden bg-gradient-to-t from-[#572DB0] to-[#0D071A] rounded-2.5 w-full p-3"
          >
            <div
              className="absolute w-[32rem] h-[32rem] rounded-full -top-32 -left-32 blur-[8rem]"
              style={{ background: "radial-gradient(50% 50% at 50% 50%, #E7C1FF 0%, #9A1AFF 100%)" }}
            />
            <div className="flex items-center w-full relative z-[1] gap-2">
              <button
                onClick={() => navigate("/audit")}
                className="flex items-center justify-center p-2 rounded-full hover:bg-bg-secondary transition-colors"
              >
                <BackIcon className="w-5 h-5 text-white" />
              </button>
              <span className="text-3xl text-white font-semibold">
                {certification?.contractName}
              </span>
              <div className="grow" />
              <button
                onClick={onShare}
                className="bg-gradient-to-b from-[rgba(255,255,255,0.03)] transition-all border border-[rgba(255,255,255,0.2)]
                   hover:bg-[rgba(255,255,255,0.27)] flex items-center gap-3
                     to-[rgba(255,255,255,0.27)] py-4 px-8 rounded-full text-white relative"
              >
                <span>Share Link to Certification</span>
              </button>
              <button
                onClick={openScan}
                className="px-12 py-4 rounded-full text-black text-center text-base bg-white hover:bg-slate-100 transition-colors"
              >
                View on {scannerConfig.find(s => s.id === certification.chain)?.name || "Scanner"}
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col overflow-y-auto pr-16 pl-10">
          <div className="flex flex-col gap-2">
            <div className="flex w-full items-center px-2.5 gap-6 mb-4">
              <span className="text-white text-2xl font-semibold grow">
                Certification Details
              </span>
            </div>
            <div className="flex items-center gap-4 px-3">
              <span className="text-white text-lg font-medium">Contract Name:</span>
              <span className="text-text-secondary text-lg">{certification.contractName}</span>
            </div>
            <div className="flex items-center gap-4 px-3">
              <span className="text-white text-lg font-medium">Issued At:</span>
              <span className="text-text-secondary text-lg">{new Date(certification.createdAt).toLocaleString()}</span>
            </div>
            <div className="flex items-center gap-4 px-3">
              <span className="text-white text-lg font-medium">Engine Version:</span>
              <span className="text-text-secondary text-lg">v{certification.engine}</span>
            </div>
            <div className="flex items-center gap-4 px-3">
              <span className="text-white text-lg font-medium">Chain:</span>
              <span className="text-text-secondary text-lg">
                {chainUrls.find((c => c.id === certification.chain))?.name}&nbsp;
                ({certification.chain})
              </span>
            </div>
            <div className="flex items-center gap-4 px-3">
              <span className="text-white text-lg font-medium">Contract Address:</span>
              <span className="text-text-secondary text-lg">{certification.address}</span>
            </div>
            <div className="flex items-center gap-4 px-3">
              <span className="text-white text-lg font-medium">Signed Bytecode Hash:</span>
              <span className="text-text-secondary text-lg">{certification.bytecodeHash}</span>
            </div>
          </div>
          <div className="flex flex-col p-10 gap-10 border border-[#1F1F1F] bg-bg-primary rounded-xl mt-10">
            <div className="flex items-center gap-4">
              <span className="text-xl text-white">
                AUDITED BY
              </span>
              <Logo />
            </div>
            <span className="text-base leading-[166%] text-text-secondary w-3/4">
              The Future of Impenetrable Smart Contracts
            </span>
          </div>
          <div className="flex flex-col gap-6 mt-10">
            <div className="flex w-full items-center px-2.5 gap-6">
              <span className="text-white text-2xl font-semibold grow">
                Detailed Analysis
              </span>
            </div>
            <Analysis audit={certification.result} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CertificationPage
