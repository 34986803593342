import { AxiosInstance } from "axios"
import axios from "./axios"
import { IProject, IProjectFileAudit, IProjectNetworkContractCertification, IProjectVersion } from "types/projects"

class ProjectsApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  async createProject(name: string) {
    return this.axios.post("/projects", { name }).then(res => res.data as IProject)
  }

  async getProjects() {
    return this.axios.get("/projects").then(res => res.data as IProject[])
  }

  async getProject(id: string) {
    return this.axios.get(`/projects/${id}`).then(res => res.data as IProject)
  }

  async updateProject(id: string, name: string) {
    return this.axios.put(`/projects/${id}`, { name }).then(res => res.data as IProject)
  }

  async uploadVersion(id: string, file: File) {
    const formData = new FormData()
    formData.append("file", file)

    return this.axios.post(
      `/projects/${id}/versions`,
      formData,
      { headers: { "Content-Type": "multipart/form-data" } },
    ).then(res => res.data.version as number)
  }

  async getVersion(projectId: string, versionId: number) {
    return this.axios.get(`/projects/${projectId}/versions/${versionId}`).then(res => res.data as IProjectVersion)
  }

  async getAudit(projectId: string, versionId: number, file: string) {
    // long running operation
    const baseUrl = "https://us-central1-sentry-62b42.cloudfunctions.net/api"

    return this.axios.get(`${baseUrl}/projects/${projectId}/versions/${versionId}/files/${file}/audit`)
      .then(res => res.data as IProjectFileAudit)
  }

  async getCertifications(projectId: string) {
    return this.axios.get(`/projects/${projectId}/certifications`).then(res => res.data as IProjectNetworkContractCertification[])
  }

  async certify(
    projectId: string,
    chainId: string,
    fileAdresses: Record<string, string>,
    compilerSettings: { solcVersion: string, optimized: boolean, runs: number },
  ) {
    return this.axios.post(`/projects/${projectId}/certifications`, { chainId, fileAdresses, compilerSettings })
      .then(res => res.data as {fulfilled: IProjectNetworkContractCertification[], failed: {error: string}[]})
  }
}

const projectsApi = new ProjectsApi()

export default projectsApi
