import { FC } from "react"

const Overview: FC = () => {
  const smartContractsReviewed = 5400
  const tvlSecured = 15000000
  const projectAudited = 300

  const numberShort = (num: number) => {
    const digits = num.toString().length
    if (digits < 4) return num
    if (digits < 7) return `${(Math.floor(num / 1000) * 1000)}+`

    return `${(Math.floor(num / 1000000))}+ Million`
  }

  return (
    <div
      className="grid grid-cols-3 relative shrink-0 gap-6
     overflow-hidden bg-gradient-to-t from-[#572DB0] to-[#0D071A] rounded-2.5 w-full py-5 px-8"
    >
      <div
        className="absolute w-[32rem] h-[32rem] rounded-full -top-32 -left-32 blur-[8rem]"
        style={{ background: "radial-gradient(50% 50% at 50% 50%, #E7C1FF 0%, #9A1AFF 100%)" }}
      />
      <div className="flex flex-col w-full relative z-[1] gap-1 border-r-[0.5px] border-r-[#D9D9D9]">
        <span className="text-sm text-[#D0D0D0]">
          Smart Contracts Reviewed
        </span>
        <span className="text-3xl text-white font-medium">
          {numberShort(smartContractsReviewed)}
        </span>
      </div>
      <div className="flex flex-col w-full relative z-[1] gap-1 border-r-[0.5px] border-r-[#D9D9D9]">
        <span className="text-sm text-[#D0D0D0]">
          TVL Secured&nbsp;
          <span
            className="text-white rounded-md text-[0.625rem] align-middle
          leading-none px-2 py-1 bg-[rgba(255,255,255,0.1)]"
          >
            COMING SOON
          </span>
        </span>
        <span className="text-3xl text-white font-medium">
          {numberShort(tvlSecured)}
        </span>
      </div>
      <div className="flex flex-col w-full relative z-[1] gap-1">
        <span className="text-sm text-[#D0D0D0]">
          Project Audits&nbsp;
          <span
            className="text-white rounded-md text-[0.625rem] align-middle
          leading-none px-2 py-1 bg-[rgba(255,255,255,0.1)]"
          >
            COMING SOON
          </span>
        </span>
        <span className="text-3xl text-white font-medium">
          {numberShort(projectAudited)}
        </span>
      </div>
    </div>
  )
}

export default Overview
