import { toast } from "react-toastify"

const toasts = {
  error: (message: string) => {
    const ref = toast(
      <div
        className="flex items-center justify-center bg-[#ee002a70]
         backdrop-blur-2xl px-5 py-3 gap-4 rounded-full mx-auto max-w-max"
      >
        <span
          className="text-base text-white text-center font-bold tracking-wide
         whitespace-pre-line"
        >{message || "An unknown error occurred"}
        </span>
      </div>,
      {
        className: "toast-wrapper",
        position: "bottom-right",
        closeButton: false,
        autoClose: 2000,
        progress: 0,
      },
    )

    return ref
  },
  success: (message: string) => {
    const ref = toast(
      <div
        className="flex items-center justify-center bg-emerald-400
       backdrop-blur-2xl px-5 py-3 gap-4 rounded-full mx-auto max-w-max"
      >
        <span
          className="text-base text-white text-center font-bold tracking-wide
         whitespace-pre-line"
        >{message || "An unknown error occurred"}
        </span>
      </div>,
      {
        className: "toast-wrapper",
        position: "bottom-right",
        closeButton: false,
        autoClose: 2000,
        progress: 0,
      },
    )

    return ref
  },
}

export default toasts
