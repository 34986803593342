import authApi from "api/auth"
import { useAuth } from "contexts/Auth"
import { FC, useCallback, useState } from "react"

const AuthorizeVSCodePage: FC = () => {
  const { user } = useAuth()
  const [submitting, setSubmitting] = useState(false)

  const openVSCode = useCallback(() => {
    if (submitting) return
    setSubmitting(true)
    authApi.getExternalTokenQuery().then(queryString => {
      window.open(`vscode://sentry-ai.SentryAI?${queryString}`, "_self")
      window.close()
    })
  }, [submitting])

  return (
    <div className="w-full h-full py-10 pl-10 pr-16">
      <div className="flex flex-col items-center justify-center h-full gap-6 grow bg-bg-secondary rounded-2.5 border border-border-primary">
        <h1 className="text-4xl font-semibold text-white">Authorize VSCode</h1>
        {!user ? (
          <span className="text-text-secondary text-base">
            You need to connect your wallet to continue
          </span>
        ): (
          <>
            <span className="text-text-secondary text-base">
              Click below to authorize VSCode
            </span>
            <button
              onClick={openVSCode}
              className="bg-white text-black hover:bg-slate-100 transition-colors text-base
              px-8 border border-transparent hover:border-black py-5 rounded-full"
            >
              Continue to VSCode
            </button>
          </>
        )}
      </div>
    </div>
  )
}

export default AuthorizeVSCodePage
