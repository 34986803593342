import auditsApi from "api/audits"
import { BackIcon } from "assets/icons"
import GoPlusButton from "components/GoPlusButton"
import Loader from "components/Loader"
import { scannerConfig } from "constants/chains"
import { useAuth } from "contexts/Auth"
import useTitle from "hooks/useTitle"
import { FC, useEffect, useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router"
import { Tooltip } from "react-tooltip"
import { IContractAudit } from "types/audits"

const categories = [
  "Arbitrary Jump/Storage Write",
  "Centralization of Control",
  "Compiler Issues",
  "Delegate Call to Untrusted Contract",
  "Dependence on Predictable Variables",
  "Ether/Token Theft",
  "Flash Loans",
  "Front Running",
  "Improper Events",
  "Improper Authorization Scheme",
  "Integer Over/Underflow",
  "Logical Issues",
  "Oracle Issues",
  "Outdated Compiler Version",
  "Race Conditions",
  "Reentrancy",
  "Signature Issues",
  "Sybil Attack",
  "Unbounded Loops",
  "Unused Code",
  "Overall Contract Safety",
]

const ContractAuditDetailPage: FC = () => {
  useTitle("Contract Audit")
  const { user } = useAuth()
  const { contract, chain } = useParams()
  const [audit, setAudit] = useState<IContractAudit>()
  const navigate = useNavigate()

  const failuresGrouped = audit?.output?.result?.failures.reduce((acc, f) => ({
    ...acc,
    [f.name]: [...(acc[f.name] || []), f.reason],
  }), {} as Record<string, string[]>)

  const failed = Object.keys(failuresGrouped || {}).filter(k => categories.includes(k)).length
  const passed = categories.length - failed

  const openScan = () => {
    const scanner = scannerConfig.find(s => s.id === chain)
    if (!scanner) return
    const link = `${scanner.website}address/${contract}`
    window.open(link, "_blank")
  }

  useEffect(() => {
    if (!contract || !chain) return

    auditsApi.getContractAudit(contract, chain)
      .then(setAudit)
      .catch(() => setAudit({ error: "Job failed. Please try again." } as IContractAudit))
  }, [user, contract, chain])

  if (!contract || !chain) return (
    <Navigate to="/audit" replace />
  )

  /*if (!user) return (
    <div className="w-full h-full py-10 pl-10 pr-16">
      <div className="flex flex-col items-center justify-center h-full gap-6 grow bg-bg-secondary rounded-2.5 border border-border-primary">
        <h1 className="text-4xl font-semibold text-white">This is an authorized area</h1>
        <span className="text-text-secondary text-base">
          You need to connect your wallet to continue
        </span>
      </div>
    </div>
  )*/

  if (!audit) return (
    <div className="w-full h-full overflow-hidden bg-black">
      <Loader />
    </div>
  )

  if (audit.error) return (
    <div className="w-full h-full py-10 pl-10 pr-16">
      <div className="flex flex-col items-center justify-center h-full gap-6 grow bg-bg-secondary rounded-2.5 border border-border-primary">
        <h1 className="text-4xl font-semibold text-white">Error</h1>
        <span className="text-text-secondary text-base">
          {audit.error}
        </span>
        <button
          onClick={() => navigate("/audit")}
          className="px-16 py-4 rounded-full bg-black border border-border-primary hover:bg-bg-secondary transition-colors"
        >
          <span className="text-base text-white">Back to Audits</span>
        </button>
      </div>
    </div>
  )

  return (
    <div className="w-full h-full overflow-y-auto pb-10">
      <div className="w-full h-full pt-10 pl-10 pr-16">
        <div className="flex flex-col gap-6">
          <div className="flex w-full items-center px-2.5 gap-6">
            <button
              onClick={() => navigate("/audit")}
              className="flex items-center justify-center p-2.5 rounded-full bg-black hover:bg-bg-secondary transition-colors"
            >
              <BackIcon className="w-5 h-5 text-white" />
            </button>
            <span className="text-white text-2xl font-semibold">
              Detailed Analysis
            </span>
            <GoPlusButton params={audit.output.goPlus} />
            <div className="grow" />
            <button
              onClick={openScan}
              className="px-12 py-4 rounded-full text-black text-center text-base bg-white hover:bg-slate-100 transition-colors"
            >
              {audit.contractName}
            </button>
          </div>
          <div
            className="flex flex-col items-center justify-center h-full mb-10 px-16 py-6
         gap-6 grow bg-bg-secondary rounded-2.5 border border-border-primary"
          >
            <div className="w-full flex items-center border-b border-b-[#282828] pb-4">
              <span className="text-xl text-white font-medium grow">
                Vulnerability Check
              </span>
              <div className="flex items-center gap-6">
                <div className="flex items-center gap-2">
                  <div className="p-3 rounded-full bg-[#26CF361A] animate-pulse">
                    <div className="w-4 h-4 rounded-full bg-[#26CF36]" />
                  </div>
                  <span className="text-[#D0D0D0]">
                    {passed} Passed
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="p-3 rounded-full bg-[#D000191A] animate-pulse">
                    <div className="w-4 h-4 rounded-full bg-[#D00019]" />
                  </div>
                  <span className="text-[#D0D0D0]">
                    {failed} Failed
                  </span>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
              {categories.map((category, i) => {
                const failure = failuresGrouped?.[category]
                const failureText = failure?.join("\n\n")
                const bgColor = failure ? "#D000190D" : "#26CF360D"
                const dotColor = failure ? "#D00019" : "#26CF36"

                return (
                  <div
                    key={i}
                    className="flex items-center w-full p-2 rounded-full"
                    style={{ backgroundColor: bgColor }}
                  >
                    <Tooltip id={category} className="z-[10] tooltip-vulnerability" place="top">
                      <div className="flex flex-col max-w-64">
                        <span className="text-[#D0D0D0] text-sm whitespace-pre-line">
                          {failure ? failureText : "No issues found"}
                        </span>
                      </div>
                    </Tooltip>
                    <div data-tooltip-id={category} className="p-3 rounded-full animate-pulse">
                      <div className="w-4 h-4 rounded-full" style={{ backgroundColor: dotColor }} />
                    </div>
                    <span className="text-[#D0D0D0] text-base">{category}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContractAuditDetailPage
