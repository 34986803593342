import { uuidV4 } from "ethers"
import { FC } from "react"

const Logo = () => {
  return (
    <svg width="102" height="107" viewBox="0 0 102 107" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* eslint-disable-next-line max-len*/}
      <path fill-rule="evenodd" clip-rule="evenodd" d="M91.9607 48.966C92.4996 48.8426 93.0561 48.7721 93.6328 48.7721C97.773 48.7721 101.13 52.1315 101.127 56.2717C101.127 60.4118 97.7705 63.7687 93.6303 63.7687C89.4902 63.7687 86.1333 60.4118 86.1333 56.2717C86.1333 54.67 86.6394 53.1892 87.4957 51.9704L51.3602 9.04795L28.3477 36.3819H21.3543L51.3602 0.73999L91.9607 48.966ZM51.3592 38.6132L40.9963 50.9228H32.0487L51.3567 27.9834L65.2528 44.491H72.2462L51.3567 19.6729L20.5525 56.2692H40.9988L51.3592 68.5738L61.7196 56.2692H70.6673L51.3567 79.2036L37.4631 62.696H30.4697L51.3542 87.5116L82.1634 50.9178H61.7196L51.3592 38.6132ZM51.3592 60.2709L45.7408 53.5948L51.3617 46.9187L56.9827 53.5948L51.3592 60.2709ZM51.3572 98.141L14.8112 54.7325C15.4735 53.6144 15.8613 52.3124 15.8613 50.9198C15.8613 46.7796 12.5044 43.4227 8.36426 43.4227C4.22412 43.4227 0.867188 46.7796 0.867188 50.9198C0.867188 55.0599 4.22412 58.4168 8.36426 58.4168C9.10465 58.4168 9.81985 58.306 10.4948 58.1046L51.3546 106.446L81.3605 70.8045H74.3671L51.3572 98.141Z" fill="white" />
    </svg>
  )
}

const Progress = () => {
  const id = uuidV4(crypto.getRandomValues(new Uint8Array(16)))
  const percentage = 1
  const length = Math.PI * 170
  const offset = percentage * length

  return (
    <svg width="170" height="170" viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <mask id={id}>
          <rect x={0} y={0} width={170} height={170} fill="black" />
          <circle
            cx={85}
            cy={85}
            r={83}
            strokeWidth={2}
            stroke="white"
            fill="black"
            strokeLinecap="round"
            strokeDashoffset={0}
            strokeDasharray={[offset].join(" ")}
          />
        </mask>
      </defs>
      <foreignObject mask={`url(#${id})`} x={0} y={0} className="overflow-visible" width={170} height={170}>
        <div
          style={{
            background: "conic-gradient(from 180deg at 50% 50%, #000000 0deg, #000000 129.6deg, #8E8E8E 360deg)",
            width: 170,
            height: 170,
          }}
        />
      </foreignObject>
    </svg>
  )
}

const Loader: FC = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="absolute -translate-x-1/2 left-1/2">
        <div className="animate-loader-logo">
          <Logo />
        </div>
      </div>
      <div className="animate-spin">
        <Progress />
      </div>
    </div>
  )
}

export default Loader
