import { NetRightSrc } from "assets/images"
import { supportedChainsConfig } from "constants/chains"
import { useAuth } from "contexts/Auth"
import { FC } from "react"
import { Outlet } from "react-router"
import Sidebar from "./Sidebar"

const shortenAddress = (address: string) => {
  return `${address.slice(0, 6)}...${address.slice(-4)}`
}

const MainLayout: FC = () => {
  const { user, signInWithEthereum, authInProgress } = useAuth()
  const chain = user?.uid.split(":")[1]
  const chainData = chain ? supportedChainsConfig.find(c => c.id === chain) : null

  const connectText = authInProgress ? "Connecting..." : user ? `${shortenAddress(user.uid.split(":")[2])}` : "Connect"

  return (
    <div className="w-full h-full flex bg-black overflow-hidden">
      <Sidebar />
      <div className="grow flex flex-col h-full overflow-hidden">
        <div className="w-full h-24 shrink-0 bg-black border-b border-b-border-primary flex items-center justify-end px-16">
          <button
            onClick={signInWithEthereum}
            disabled={authInProgress}
            className="bg-bg-primary flex items-center gap-3
              hover:bg-[rgba(255,255,255,0.1)]
             text-white border border-border-primary transition-colors text-base px-5 gap-2 py-3 rounded-lg"
          >
            <div dangerouslySetInnerHTML={{ __html: chainData?.svg || "" }} className="[&>svg]:w-8 [&>svg]:h-8" />
            {connectText}
          </button>
        </div>
        <div className="bg-black grow w-full relative">
          <div className="grow h-full w-full absolute z-[1]">
            <Outlet />
          </div>
          <img src={NetRightSrc} alt="" className="absolute right-0 bottom-0 translate-y-1/2" />
        </div>
      </div>
    </div>
  )
}

export default MainLayout
