import Modal from "components/Modal"
import { AwaitableModal } from "hooks/useAwaitableModal"
import { Tooltip } from "react-tooltip"
import { GoPlusTokenSecurityParams } from "types/goplus"

const renderYesNo = (value: boolean | "unknown", invertColors = false) => (
  value === true ? (
    <span
      className={["font-medium",
        invertColors ? "text-red-500" : "text-green-500"].asClass}
    >Yes
    </span>
  ): value === false
    ? (
      <span
        className={["font-medium",
          !invertColors ? "text-red-500" : "text-green-500"].asClass}
      >No
      </span>
    ) : (
      <span className="text-yellow-500">Unknown</span>
    )
)

const renderNumber = (value: string | number | "unknown") => (
  value === "unknown" ? value : Number(value).toLocaleString()
)

const GoPlusTokenSecurity: AwaitableModal<undefined, {params: GoPlusTokenSecurityParams}> = ({ open, initialData, resolve }) => {
  if (!initialData?.params) return null
  const {
    is_open_source,
    is_proxy, is_mintable,
    owner_address, can_take_back_ownership, gas_abuse, is_true_token, token_name, token_symbol, total_supply, sell_tax, buy_tax,
    cannot_buy, cannot_sell_all, owner_change_balance, is_honeypot, slippage_modifiable, hidden_owner, transfer_pausable, trading_cooldown,
    is_blacklisted, is_whitelisted, is_anti_whale, holder_count, lp_holder_count, owner_balance, creator_address, creator_balance,
  } = initialData.params

  // taxation and fees
  const sellTax = sell_tax || "unknown"
  const buyTax = buy_tax || "unknown"

  // Contract Ownership and Accessibility
  const ownerAddress = owner_address !== "" ? (owner_address || "unknown") : ""
  const retakeOwnership = can_take_back_ownership ? can_take_back_ownership === "1" : "unknown"
  const hasOwner = owner_address !== "unknown" ? !!owner_address : "unknown"

  const isTaxModifiable = slippage_modifiable ? slippage_modifiable === "1" : "unknown"
  const ownershipRenounced = !hasOwner && !retakeOwnership
  const isHiddenOwner = hidden_owner ? hidden_owner === "1" : "unknown"
  const isOpenSource = is_open_source ? is_open_source === "1" : "unknown"

  //Security Features:
  const isHoneypot = is_honeypot ? is_honeypot === "1" : "unknown"
  const isProxy = is_proxy ? is_proxy === "1" : "unknown"
  const isMintable = is_mintable ? is_mintable === "1" : "unknown"
  const isTransferPausable = transfer_pausable ? transfer_pausable === "1" : "unknown"
  const tradingCooldown = trading_cooldown ? trading_cooldown === "1" : "unknown"
  const cannotSellAll = cannot_sell_all ? cannot_sell_all === "1" : "unknown"
  const ownerChangeBalance = owner_change_balance ? owner_change_balance === "1" : "unknown"
  const hasBlacklist = is_blacklisted ? is_blacklisted === "1" : "unknown"
  const hasWhitelist = is_whitelisted ? is_whitelisted === "1" : "unknown"
  const isAntiWhale = is_anti_whale ? is_anti_whale === "1" : "unknown"
  //const gasAbuse = gas_abuse ? gas_abuse === "1" : "unknown"

  // token info
  const tokenName = token_name || "unknown"
  //const fakeToken = is_true_token === "0"
  const tokenSymbol = token_symbol || "unknown"
  const totalSupply = total_supply || "unknown"
  const cannotBuy = cannot_buy ? cannot_buy === "1" : "unknown"

  //Tokenomics:
  const holderCount = holder_count || "unknown"
  const lpHolderCount = lp_holder_count || "unknown"

  //Identifiers and Balances:
  const ownerBalance = owner_balance || "unknown"
  const creatorAddress = creator_address !== "" ? (creator_address || "unknown") : ""
  const creatorBalance = creator_balance || "unknown"


  return (
    <Modal open={open} close={resolve}>
      <Tooltip id="tooltip" place="left" />
      <div className="bg-bg-primary rounded-lg shadow-lg h-max max-h-full flex flex-col">
        <h1 className="text-2xl font-bold p-4 text-white">Extra Info</h1>
        <div className="overflow-y-auto px-4 max-h-max">
          <div className="flex flex-col h-full gap-2">
            <div className="flex flex-col gap-2">
              <h2 className="text-white text-lg font-semibold">Taxation and Fees</h2>
              <div className="flex flex-col gap-1 divide-y divide-[#FFFFFF10]">
                <div className="flex items-center justify-between">
                  <span className="text-white">Sell Tax:</span>
                  <span className="font-medium text-white">
                    {sellTax === "unknown" ? sellTax : `${Number(sellTax)*100}%`}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-white">Buy Tax:</span>
                  <span className="font-medium text-white">
                    {buyTax === "unknown" ? buyTax : `${Number(buyTax)*100}%`}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 mt-4">
              <h2 className="text-white text-lg font-semibold">Contract Ownership and Accessibility</h2>
              <div className="flex flex-col gap-1 divide-y divide-[#FFFFFF10]">
                <div className="flex items-center justify-between">
                  <span className="text-white">Tax Modifiable:</span>
                  <span className="font-medium text-white">{renderYesNo(isTaxModifiable, true)}</span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-white">Ownership Renounced:</span>
                  <span className="font-medium text-white">{renderYesNo(ownershipRenounced)}</span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-white">Hidden Owner:</span>
                  <span className="font-medium text-white">{renderYesNo(isHiddenOwner, true)}</span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-white">Open Source:</span>
                  <span className="font-medium text-white">{renderYesNo(isOpenSource)}</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 mt-4">
              <h2 className="text-white text-lg font-semibold">Security Features</h2>
              <div className="flex flex-col gap-1 divide-y divide-[#FFFFFF10]">
                <div
                  data-tooltip-content="Usually indicating whether a contract is a potential scam or not"
                  data-tooltip-id="tooltip"
                  className="flex items-center justify-between"
                >
                  <span className="text-white">Honeypot:</span>
                  <span className="font-medium text-white">{renderYesNo(isHoneypot, true)}</span>
                </div>
                <div
                  data-tooltip-content="Can be related to upgradability or security"
                  data-tooltip-id="tooltip"
                  className="flex items-center justify-between"
                >
                  <span className="text-white">Proxy:</span>
                  <span className="font-medium text-white">{renderYesNo(isProxy, true)}</span>
                </div>
                <div
                  data-tooltip-content="Whether new tokens can be created after initial supply"
                  data-tooltip-id="tooltip"
                  className="flex items-center justify-between"
                >
                  <span className="text-white">Mintable:</span>
                  <span className="font-medium text-white">{renderYesNo(isMintable, true)}</span>
                </div>
                <div
                  data-tooltip-content="Whether token transfers can be paused"
                  data-tooltip-id="tooltip"
                  className="flex items-center justify-between"
                >
                  <span className="text-white">Transfer Pausable:</span>
                  <span className="font-medium text-white">{renderYesNo(isTransferPausable, true)}</span>
                </div>
                <div
                  data-tooltip-content="Prevents rapid trading to reduce manipulation"
                  data-tooltip-id="tooltip"
                  className="flex items-center justify-between"
                >
                  <span className="text-white">Trading Cooldown:</span>
                  <span className="font-medium text-white">{renderYesNo(tradingCooldown, true)}</span>
                </div>
                <div
                  data-tooltip-content="Restrictions on selling total holdings"
                  data-tooltip-id="tooltip"
                  className="flex items-center justify-between"
                >
                  <span className="text-white">Can't Sell All:</span>
                  <span className="font-medium text-white">{renderYesNo(cannotSellAll, true)}</span>
                </div>
                <div
                  data-tooltip-content="If the owner has the power to alter balances"
                  data-tooltip-id="tooltip"
                  className="flex items-center justify-between"
                >
                  <span className="text-white">Owner Can Change Balance:</span>
                  <span className="font-medium text-white">{renderYesNo(ownerChangeBalance, true)}</span>
                </div>
                <div
                  data-tooltip-content="Some addresses can be prevented from buying/selling"
                  data-tooltip-id="tooltip"
                  className="flex items-center justify-between"
                >
                  <span className="text-white">Has Blacklist:</span>
                  <span className="font-medium text-white">{renderYesNo(hasBlacklist, true)}</span>
                </div>
                <div
                  data-tooltip-content="Only some addresses can be allowed to buy/sell under specific conditions"
                  data-tooltip-id="tooltip"
                  className="flex items-center justify-between"
                >
                  <span className="text-white">Has Whitelist:</span>
                  <span className="font-medium text-white">{renderYesNo(hasWhitelist, true)}</span>
                </div>
                <div
                  data-tooltip-content="Measures to prevent large holders from market manipulation"
                  data-tooltip-id="tooltip"
                  className="flex items-center justify-between"
                >
                  <span className="text-white">Is Anti Whale:</span>
                  <span className="font-medium text-white">{renderYesNo(isAntiWhale)}</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 mt-4">
              <h2 className="text-white text-lg font-semibold">Tokenomics</h2>
              <div className="flex flex-col gap-1 divide-y divide-[#FFFFFF10]">
                <div className="flex items-center justify-between">
                  <span className="text-white">Token Name:</span>
                  <span className="font-medium text-white">{tokenName}</span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-white">Token Symbol:</span>
                  <span className="font-medium text-white">{tokenSymbol}</span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-white">Total Supply:</span>
                  <span className="font-medium text-white">{renderNumber(totalSupply)}</span>
                </div>
                <div
                  className="flex items-center justify-between"
                >
                  <span className="text-white">Cannot Buy:</span>
                  <span className="font-medium text-white">{renderYesNo(cannotBuy, true)}</span>
                </div>
                <div
                  data-tooltip-content="Total number of unique addresses holding the token"
                  data-tooltip-id="tooltip"
                  className="flex items-center justify-between"
                >
                  <span className="text-white">Holder Count</span>
                  <span className="font-medium text-white">{renderNumber(holderCount)}</span>
                </div>
                <div
                  data-tooltip-content="Number of liquidity provider token holders"
                  data-tooltip-id="tooltip"
                  className="flex items-center justify-between"
                >
                  <span className="text-white">LP Holder Count</span>
                  <span className="font-medium text-white">{renderNumber(lpHolderCount)}</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 mt-4">
              <h2 className="text-white text-lg font-semibold">Identifiers and Balances</h2>
              <div className="flex flex-col gap-1 divide-y divide-[#FFFFFF10]">
                <div
                  data-tooltip-content="The wallet address of the contract creator"
                  data-tooltip-id="tooltip"
                  className="flex items-center justify-between gap-8"
                >
                  <span className="text-white">Creator Address:</span>
                  <span className="font-medium text-white text-right max-w-[20rem] truncate">{creatorAddress}</span>
                </div>
                <div
                  data-tooltip-content="How many tokens the creator holds"
                  data-tooltip-id="tooltip"
                  className="flex items-center justify-between"
                >
                  <span className="text-white">Creator Balance:</span>
                  <span className="font-medium text-white">{renderNumber(creatorBalance)}</span>
                </div>
                <div
                  data-tooltip-id="tooltip"
                  data-tooltip-content="The wallet address of the current owner"
                  className="flex items-center justify-between gap-8"
                >
                  <span className="text-white">Owner Address:</span>
                  <span className="font-medium text-white text-right max-w-[20rem] truncate">{ownerAddress}</span>
                </div>
                <div
                  data-tooltip-id="tooltip"
                  data-tooltip-content="How many tokens the owner holds"
                  className="flex items-center justify-between"
                >
                  <span className="text-white">Owner Balance:</span>
                  <span className="font-medium text-white">{renderNumber(ownerBalance)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center p-4 gap-4">
          <button
            onClick={() => resolve()}
            className="text-white border
                    bg-gradient-to-t from-[#7739C5] to-[#974DDA]
                    border-transparent
                    hover:border-border-primary
                    hover:opacity-90
                    transition-all
                    px-4 py-2 rounded-lg mr-4"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default GoPlusTokenSecurity
