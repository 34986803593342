import projectsApi from "api/projects"
import Modal from "components/Modal"
import { AwaitableModal } from "hooks/useAwaitableModal"
import { useState } from "react"
import { useNavigate } from "react-router"

const CreateProjectModal: AwaitableModal = ({ resolve, open }) => {
  const [name, setName] = useState<string>("")
  const [submitting, setSubmitting] = useState<boolean>(false)
  const navigate = useNavigate()

  const create = async() => {
    setSubmitting(true)
    try {
      await projectsApi.createProject(name).then(project => {
        navigate(`/audit/project/${project.id}`)
      })
    } catch (e) {
      console.error(e)
      resolve(false)
    }
  }

  return (
    <Modal open={open} close={() => resolve(false)}>
      <div className="w-full h-max p-10 rounded-2.5 bg-bg-secondary border border-border-primary">
        <div className="flex flex-col items-center justify-center h-full gap-6 grow">
          <h1 className="text-2xl font-semibold text-white">Create a Project</h1>
          <span className="text-text-secondary text-base">
            Give your project a name and start auditing
          </span>
          <input
            className="rounded-full border border-border-primary py-4 px-8 bg-bg-secondary outline-none text-white text-center"
            placeholder="Enter Project Name"
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <button
            onClick={create}
            disabled={submitting}
            className="px-16 py-4 rounded-full bg-black border border-border-primary hover:bg-bg-secondary transition-colors"
          >
            <span className="text-base text-white">{submitting ? "..." : "Create"}</span>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default CreateProjectModal
